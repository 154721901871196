const getSection = (section: string, selector: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(section, "text/html");

  if (selector) {
    return doc.querySelector(selector);
  }

  return doc;
};

export default getSection;
